// extracted by mini-css-extract-plugin
export var checkbox = "what-fits-you-section-module--checkbox--scYvo";
export var figContent = "what-fits-you-section-module--fig-content--84nVl";
export var figHeading = "what-fits-you-section-module--fig-heading--mYFnw";
export var figIcon = "what-fits-you-section-module--fig-icon--OGhCR";
export var figureAttention = "what-fits-you-section-module--figure-attention--vU4qO";
export var figureAttr = "what-fits-you-section-module--figure-attr--ex3fq";
export var figureContent = "what-fits-you-section-module--figure-content--SxNBu";
export var figureHeading = "what-fits-you-section-module--figure-heading--r5c2X";
export var figureTitle = "what-fits-you-section-module--figure-title--LTzcP";
export var figureWrapper = "what-fits-you-section-module--figure-wrapper--jEadt";
export var fitsContainer = "what-fits-you-section-module--fits-container--CBye3";
export var fitsElements = "what-fits-you-section-module--fits-elements--IYF0u";
export var fitsNegative = "what-fits-you-section-module--fits-negative--NLESe";
export var fitsNegativeText = "what-fits-you-section-module--fits-negative-text--f13oL";
export var fitsPositive = "what-fits-you-section-module--fits-positive--hgPOr";
export var warn = "what-fits-you-section-module--warn--WlyLR";