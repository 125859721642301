import React from "react";

import {
    fitsContainer,
    figureAttr,
    figureTitle,
    figureContent,
    figureWrapper,
    warn,
    figureAttention,
    figIcon,
    figHeading,
    figContent,
    figureHeading,
    fitsPositive,
    fitsElements,
    checkbox,
    fitsNegative,
    fitsNegativeText,
} from "./what-fits-you-section.module.scss";
import CechySylwetki from "../../assets/images/svg/cechy-sylwetki.svg";
import Warning from "../../assets/images/svg/circle.svg";
import Clue from "../../assets/images/svg/clue.svg";
import Positive from "../../assets/images/svg/checked-positive.svg";
import Negative from "../../assets/images/svg/checked-negative.svg";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import getTranslationKey from "../../utils/get-translation-key";

import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import Title from "../atoms/title";

export interface ISectionRowProps {
    className?: string;
    title?: string;
    content?: string;
    warning?: string;
    image?: React.ReactElement;
    clue?: string;
    heading?: string;
    translationKeyPositive: string;
    translationKeyNegative: string;
}

type ChecklistType = { content: string };

const WhatFitsYouSection: React.FC<ISectionRowProps> = ({
    image,
    title,
    content,
    warning,
    clue,
    heading,
    translationKeyPositive,
    translationKeyNegative,
}) => {
    const { i18n, language } = useI18next();
    const fitsTranslations = getTranslationKey("what-fits-you");

    const checklistPositive: ChecklistType[] = i18n.getResource(
        language,
        "translation",
        fitsTranslations(translationKeyPositive)
    );
    const checklistNegative: ChecklistType[] = i18n.getResource(
        language,
        "translation",
        fitsTranslations(translationKeyNegative)
    );
    return (
        <>
            <SectionWrapper>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={fitsContainer}
                >
                    {image && <div>{image}</div>}
                </Section>
                <Section column={"right"} columnTablet={"regular"} columnPhone={"regular"}>
                    <CechySylwetki className={figureAttr} />
                    <Title size={"large"} fontStyle={"italic"} className={figureTitle}>
                        {title}
                    </Title>
                    <div className={figureContent}>{content}</div>
                </Section>
            </SectionWrapper>
            <SectionWrapper style={{ padding: "0 0 4em" }}>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={`${figureWrapper} ${warn}`}
                >
                    <div className={figureAttention}>
                        <Warning className={figIcon} />
                        <p className={figHeading}>Uwaga!</p>
                    </div>
                    <div className={figContent}>{warning}</div>
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={figureWrapper}
                >
                    <div className={figureAttention}>
                        <Clue className={figIcon} />
                        <p className={figHeading}>Wskazówka</p>
                    </div>
                    <div className={figContent}>{clue}</div>
                </Section>
            </SectionWrapper>
            <SectionWrapper bgColor={"blue-light"}>
                <Section column={"left"} columnTablet={"regular"} columnPhone={"regular"}>
                    <Title
                        size={"large"}
                        align={"left"}
                        fontStyle={"italic"}
                        className={figureHeading}
                    >
                        {heading}
                    </Title>
                </Section>
                <Section column={"right"} columnTablet={"regular"} columnPhone={"regular"}>
                    <ul className={fitsPositive}>
                        {checklistPositive.map((element) => (
                            <li
                                className={fitsElements}
                                key={`checklist-positive-${element.content}`}
                            >
                                <Positive className={checkbox} />
                                {element.content}
                            </li>
                        ))}
                    </ul>
                </Section>
                <Section column={"right"} columnTablet={"regular"} columnPhone={"regular"}>
                    <Title size={"small"} fontStyle={"italic"} className={fitsNegativeText}>
                        Unikaj!
                    </Title>
                    <ul className={fitsNegative}>
                        {checklistNegative.map((element) => (
                            <li
                                className={fitsElements}
                                key={`checklist-negative-${element.content}`}
                            >
                                <Negative className={checkbox} />
                                {element.content}
                            </li>
                        ))}
                    </ul>
                </Section>
            </SectionWrapper>
        </>
    );
};

export default WhatFitsYouSection;
