import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import {
    fitsIntro,
    icon,
    fitsHeading,
    fitsAbout,
    fitsAboutText,
    fitsAboutSubtext,
    fitsImg,
    fitsQuotation,
    quoteText,
    shapeImg,
    quoteSignature,
} from "./what-fits-you.module.scss";
import Sylwetka from "../../assets/images/svg/sylwetka.svg";
import Hourglass from "../../assets/images/svg/hourglass.svg";
import Pear from "../../assets/images/svg/pear.svg";
import Apple from "../../assets/images/svg/apple.svg";
import Rectangle from "../../assets/images/svg/rectangle.svg";
import Ultra from "../../assets/images/svg/ultra.svg";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import usePageMetadata from "../../hooks/use-page-metadata";
import getTranslationKey from "../../utils/get-translation-key";

import MainLayout from "../../layouts/main-layout";
import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import Title from "../atoms/title";
import FooterStylizeSection from "../molecules/footer-stylize-section";
import WhatFitsYouSection, { ISectionRowProps } from "../organisms/what-fits-you-section";

interface IFitsProps extends IPageBase {
    readonly data: { site: ISite } & { [key: string]: ImageDataLike };
}

type BodyShapeType = { id: number } & ISectionRowProps;

const WhatFitsYou: React.FC<IFitsProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const fitsTranslations = getTranslationKey("what-fits-you");

    const { site, coverImg } = data;
    const figureImage = getImage(coverImg);
    const { pageTitle, defaultStructuredData } = usePageMetadata(pageContext, site);

    const bodyShape: BodyShapeType[] = [
        {
            id: 70,
            image: <Hourglass className={shapeImg} />,
            title: t(fitsTranslations("hourglass.title")),
            content: t(fitsTranslations("hourglass.content")),
            warning:
                "Klepsydra posiada często lekko wystający brzuch, krótki korpus i lekko wystający brzuch i duże piersi. Jednak te jej cechy nie są regułą.",
            clue:
                "Klepsydra nie powinna zwiększać dysproporcji między poszczególnymi partiami ciała, a próbować zachować równowagę między nimi.",
            heading: "W co powinna ubierać się klepsydra?",
            translationKeyPositive: "hourglass.checklist-positive",
            translationKeyNegative: "hourglass.checklist-negative",
        },
        {
            id: 71,
            image: <Pear className={shapeImg} />,
            title: t(fitsTranslations("pear.title")),
            content: t(fitsTranslations("pear.content")),
            warning:
                "Czasem u gruszek występują opadającego ramiona i tęgie nogi. Występuje różnica w rozmiarze ubrań między górną i dolną częścią sylwetki.",
            clue:
                "Powinnaś wyrównać proporcje między górą, a dołem sylwetki. Staraj się wysmuklić biodra i podkreśl górną część sylwetki.",
            heading: "W co powinna ubierać się gruszka?",
            translationKeyPositive: "pear.checklist-positive",
            translationKeyNegative: "pear.checklist-negative",
        },
        {
            id: 72,
            image: <Apple className={shapeImg} />,
            title: t(fitsTranslations("apple.title")),
            content: t(fitsTranslations("apple.content")),
            warning:
                "Sylwetka jabłko często mają rozmiar plus size, choć oczywiście nie jest to zasadą – pamiętaj, że w rozróżnieniu typów figur liczą się proporcje ciała, a nie waga.",
            clue:
                "Warto zrównoważyć proporcje sylwetki - zmniejszyć optycznie brzuch, wyeksponować nogi i wydobyć talię.",
            heading: "W co powinno ubierać się jabłko?",
            translationKeyPositive: "apple.checklist-positive",
            translationKeyNegative: "apple.checklist-negative",
        },
        {
            id: 73,
            image: <Rectangle className={shapeImg} />,
            title: t(fitsTranslations("rectangle.title")),
            content: t(fitsTranslations("rectangle.content")),
            warning: "Często mylona z sylwetką jabłkiem.",
            clue:
                "Podkreśl biodra, tak by wydawały się większe, by sylwetka nabrała kobiecości. Wysmuklisz górę, zmniejszysz linię ramion i wyeksponujesz nogi.",
            heading: "W co powinien ubierać się prostokąt?",
            translationKeyPositive: "rectangle.checklist-positive",
            translationKeyNegative: "rectangle.checklist-negative",
        },
        {
            id: 74,
            image: <Ultra className={shapeImg} />,
            title: t(fitsTranslations("ultra.title")),
            content: t(fitsTranslations("ultra.content")),
            warning:
                "Bardzo ważny jest tu odpowiednio dobrany fason, Twoje kobiece kształty mogą być Twoim atutem.",
            clue:
                "Pamiętaj, że najbardziej kobieco wyglądasz, jeśli twoja stylizacji uwzględnia proporcje sylwetki.",
            heading: "W co powinna ubierać się sylwetka ultrakobieca?",
            translationKeyPositive: "ultra.checklist-positive",
            translationKeyNegative: "ultra.checklist-negative",
        },
    ];

    return (
        <MainLayout
            SEOProps={{
                title: pageTitle,
                structuredData: defaultStructuredData,
            }}
        >
            <SectionWrapper bgColor={"beige-light"}>
                <Section
                    column={"full"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={fitsIntro}
                >
                    <Sylwetka className={icon} />
                    <Title size={"large"} align={"center"} className={fitsHeading}>
                        Określ figurę i dowiedz się co Ci pasuje.
                    </Title>
                </Section>
            </SectionWrapper>
            <SectionWrapper>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={fitsAbout}
                >
                    <div className={fitsAboutText}>{t(fitsTranslations("about"))}</div>
                    <div className={fitsAboutSubtext}>{t(fitsTranslations("subtext"))}</div>
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={fitsImg}
                >
                    {figureImage && <GatsbyImage image={figureImage} alt={""} />}
                </Section>
            </SectionWrapper>
            <SectionWrapper bgColor={"violet-dark"}>
                <Section
                    column={"narrow-right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={fitsQuotation}
                >
                    <div className={quoteText}>
                        <p>Rozmiar twojego ciała jest nieważny, moda zamaskuje wszystko.</p>
                    </div>
                    <div className={quoteSignature}>Anna Dello Russo</div>
                </Section>
            </SectionWrapper>

            {bodyShape.map((shape) => {
                return <WhatFitsYouSection key={shape.id} {...shape} />;
            })}
            <FooterStylizeSection data={data} />
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        coverImg: file(relativePath: { eq: "figures.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        logo: file(relativePath: { eq: "emilka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default WhatFitsYou;
