// extracted by mini-css-extract-plugin
export var fitsAbout = "what-fits-you-module--fits-about--LofJd";
export var fitsAboutSubtext = "what-fits-you-module--fitsAboutSubtext--+lBQh";
export var fitsAboutText = "what-fits-you-module--fits-about-text--07Amb";
export var fitsHeading = "what-fits-you-module--fits-heading--r-SVN";
export var fitsImg = "what-fits-you-module--fits-img--pc96P";
export var fitsIntro = "what-fits-you-module--fits-intro--IRq0O";
export var fitsQuotation = "what-fits-you-module--fits-quotation--U16fA";
export var icon = "what-fits-you-module--icon--vou7-";
export var quoteSignature = "what-fits-you-module--quote-signature--U87il";
export var quoteText = "what-fits-you-module--quote-text--DtMSp";
export var ratio = "what-fits-you-module--ratio--JpIzZ";
export var shapeImg = "what-fits-you-module--shape-img--p8DeW";